define("discourse/plugins/dsc-hide-post-toggle/discourse/initializers/dsc-hide-post.toggle", ["exports", "discourse/lib/ajax", "discourse/lib/plugin-api"], function (_exports, _ajax, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable no-unused-vars */
  /* eslint-disable no-console */
  /* eslint-disable no-alert */

  const h = require("virtual-dom").h;
  // import {Topic} from "discourse/models/topic";
  // import {User} from "discourse/models/user";

  //Version : 0.0.2
  var _default = _exports.default = {
    name: "dsc-hide-post-toggle",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.7", api => {
        // console.log('API', api.container);
        //############################################ s-for non_admin users############################################
        //############################################ s - All External API Functions ######################################
        function getLatestTopic() {
          return (0, _ajax.ajax)(`/latest.json`, {
            type: "GET"
          });
        }
        function getReviewQueue(review_id) {
          (0, _ajax.ajax)(`/review.json`).then(_ref => {
            let {
              reviewables
            } = _ref;
            // console.log('Reviewables', reviewables);
            reviewables.forEach(review => {
              // console.log('Review', review);
              if (review_id === review.id) {
                // console.log("Review ID", typeof (review.id), review.id);
                // console.log("Review Target URL", typeof (review.target_url), review.target_url);
                // window.location.href = review.target_url;
                window.open(review.target_url, '_blank');
              }
            });
          });
        }
        function getSingleTopic(topic_id) {
          return (0, _ajax.ajax)(`/t/${topic_id}.json`, {
            type: "GET"
          });
        }

        //############################################ e - All External API Functions ######################################

        //################### s- version 0.0.2 ##########################
        //################### e- version 0.0.2 ##########################

        //################### s- version 0.0.1.50 ##########################
        document.addEventListener("click", elem => {
          activityTab();
        });
        function activityTab() {
          const operating_user = api.getCurrentUser();
          api.onPageChange(() => {
            const router_activity = api.container.lookup("service:router");
            const activity_router_name = router_activity.currentRoute.name;
            // console.log('activity_router_name',activity_router_name); //full-page-search
            if (activity_router_name === "userActivity.index" || activity_router_name === "userActivity.replies") {
              // const userContent = document.querySelectorAll(".user-content");
              // const data_user_card = document.querySelector("[data-user-card]");
              // console.log("Function user Stream", userContent);
              // console.log("Data User card", data_user_card);

              const userStreamItems = document.querySelectorAll(".user-stream-item.deleted");
              if (userStreamItems != null) {
                // console.log("User stream:",userStreamItems);
                userStreamItems.forEach(user_stream => {
                  if (!operating_user.admin) {
                    user_stream.style.display = "none";
                  }
                });
              }
            }
          });
        }
        activityTab();

        //################### e- version 0.0.1.50 ##########################

        //############################################ s - v0.0.1.46 ###################################################
        api.decorateWidget("post:after", helper => {
          getSingleTopic(helper.attrs.topicId).then(tid => {
            const posts_length = tid.post_stream.posts.length;
            $(document).ready(function () {
              let repliesElement = $('li.replies');
              if (repliesElement[0]) {
                const span_class = repliesElement[0].querySelector('span.number');
                span_class.textContent = posts_length - 1;
              }
            });
          });
        });
        //############################################ e - v0.0.1.46 ###################################################

        //############################################ s - v0.0.1.40 ###################################################

        api.modifyClass("component:topic-list", {
          pluginId: "dsc-hide-post-toggle",
          didInsertElement: function () {
            this._super();
            const topics = this.topics;
            // const model1 = this.attrs.topics;
            // console.log('Model1', model1);
            // console.log("topics", typeof (topics), topics[0]);
            // const users = this.parentView.model.users;
            // console.log("Users,", users.length,users);
            for (let key in topics) {
              if (topics.hasOwnProperty(key)) {
                // console.log(`${key}: ${topics[key]}`);
                let newTopicLatest = [];
                const topic_id = topics[key].id;
                const topic_posters = topics[key].posters;
                topic_posters.forEach(poster => {
                  if (poster && poster.extras !== null && poster.extras.includes("latest")) {
                    newTopicLatest = poster["description"].split(",");
                  }
                });
                let userListLatest = newTopicLatest;
                if (userListLatest.length > 1 && userListLatest[0] === "Original Poster") {
                  userListLatest = userListLatest.slice(1);
                  userListLatest[0] = userListLatest[0].trim();
                }
                let i = 0;
                const element = $('tr[data-topic-id="' + topic_id + '"]').find("a[data-user-card]");
                if (element.length > 0) {
                  element.each(function () {
                    const poster_inside = topic_posters[i];
                    // /console.log("poster_inside1",typeof(poster_inside),poster_inside);
                    if (poster_inside !== undefined) {
                      if (Object.keys(poster_inside).length !== 0) {
                        const userElement = $(this);
                        // if (!userListLatest.includes(poster_inside.user_id.toString())) {
                        if (!userListLatest.includes(poster_inside.user.id.toString())) {
                          userElement.hide();
                          // console.log("poster_inside1",typeof(poster_inside.user.id),poster_inside.user_id,poster_inside.user.id);
                          // console.log("Object.keys(topic_posters)", poster_inside.user_id, poster_inside.user.name,poster_inside.user.id);
                          // console.log('userListLatest',userListLatest);
                          // console.log("poster_inside2",typeof(poster_inside.user_id),poster_inside.user_id,poster_inside.user.id);
                        }
                      }
                    }
                    i++;
                  });
                }
                //############################################ s - v0.0.1.46 ###################################################
                getSingleTopic(topic_id).then(t_id => {
                  const topic_post_count = t_id.post_stream.posts.length;
                  const element15 = $('tr[data-topic-id="' + topic_id + '"] td.num.posts-map.posts').find('span.number');
                  const element16 = $('tr[data-topic-id="' + topic_id + '"] td.num.posts-map.posts'); //.find('title');
                  if (element15[0] && element16) {
                    element15[0].textContent = topic_post_count - 1;
                    element16.removeAttr('title');
                    // element16.attr('aria-label', 'This topic has ' + topic_post_count -1 + ' replies');
                  }
                });
                //############################################ e - v0.0.1.46 ###################################################
              }
            }
          }
        });

        //############################################ e - v0.0.1.40 ###################################################

        //############################################ s - v0.0.1.41 ###################################################
        //############################################ e - v0.0.1.41 ###################################################

        //############################################ s - v0.0.1.33 ###################################################
        document.addEventListener('click', function (event) {
          const clickedElement = event.target;
          const parentElement = clickedElement.parentElement;
          if (parentElement.classList.contains('alert-info')) {
            const latest2 = getLatestTopic();
            latest2.then(response => {
              const topic_list = response.topic_list.topics;
              topic_list.forEach(topic => {
                let newTopicLatest = [];
                if (topic.posters.length > 0) {
                  topic.posters.forEach(posters => {
                    if (posters && posters.extras != null && posters.extras.includes("latest")) {
                      newTopicLatest = posters["description"].split(",");
                    }
                  });
                }
                let userListLatest = newTopicLatest;
                if (userListLatest.length > 1 && userListLatest[0] === "Original Poster") {
                  userListLatest = userListLatest.slice(1);
                  userListLatest[0] = userListLatest[0].trim();
                }
                let i = 0;
                const element = $('tr[data-topic-id="' + topic.id + '"]').find("a[data-user-card]");
                if (element.length > 0) {
                  element.each(function () {
                    const poster = topic.posters[i];
                    if (poster) {
                      if (Object.keys(poster).length !== 0) {
                        const userElement = $(this);
                        if (!userListLatest.includes(poster.user_id.toString())) {
                          userElement.hide();
                        }
                      }
                    }
                    i++;
                  });
                }
              });
            }, error => {
              console.error(error);
            });
          }
        });

        //############################################ e - v0.0.1.33 ###################################################

        //############################################ e - v0.0.1.18 ####################################################

        let lastPost, postID, relativePostDateElement, dateTimePostStream, textContentPostStream;
        document.addEventListener("DOMContentLoaded", function () {
          let postStream = document.querySelector('.post-stream');
          // console.log('postStream',postStream);
          if (postStream) {
            // lastPost = postStream.querySelector('.relative-date:last-child');
            // lastPost = postStream.querySelector('.relative-date');
            lastPost = postStream.querySelectorAll('.relative-date');
            // console.log('lastPost',lastPost);
            if (lastPost) {
              textContentPostStream = lastPost[lastPost.length - 1].textContent.trim();
              // console.log("Text Content:", textContentPostStream);
            }
          }
          let timelineContainer = document.querySelector('.timeline-container');
          if (timelineContainer) {
            let relativeTimeDateElement = timelineContainer.querySelector('.relative-date');
            if (relativeTimeDateElement) {
              // console.log("Post Stream:", textContentPostStream);
              relativeTimeDateElement.textContent = textContentPostStream;
            }
          }
        });
        api.onPageChange((url, title) => {
          const router = api.container.lookup("service:router");
          const router_name = router.currentRoute.name;
          if (router_name === "discovery.latest" || router_name === "topic.fromParamsNear" || router_name === "topic.fromParams") {
            // console.log('router_name', router_name);
            document.addEventListener("DOMContentLoaded", function () {
              let postStream = document.querySelector('.post-stream');
              if (postStream) {
                // lastPost = postStream.querySelector('.relative-date:last-child');
                lastPost = postStream.querySelectorAll('.relative-date');
                if (lastPost) {
                  textContentPostStream = lastPost[lastPost.length - 1].textContent.trim();
                  // console.log("Text Content:", textContentPostStream);
                }
              }
              let timelineContainer = document.querySelector('.timeline-container');
              if (timelineContainer) {
                // console.log("timelineContainer",timelineContainer);
                let relativeTimeDateElement = timelineContainer.querySelector('.relative-date');
                if (relativeTimeDateElement) {
                  // console.log("Post Stream:", textContentPostStream);
                  relativeTimeDateElement.textContent = textContentPostStream;
                }
              }
            });
          }
        });
        //############################################ s - v0.0.1.18 ####################################################

        //############################################ e-for non_admin users############################################

        const currentUser = api.getCurrentUser();
        if (!currentUser || !currentUser.admin) {
          //############################################ s-e Current User is non-admin #######################################
          return;
        }

        //############################################ Current User is admin #######################################
        //############################################ s - v0.0.1.34 ###################################################
        document.addEventListener('DOMContentLoaded', function () {
          const review_queue_router = api.container.lookup("service:router");
          api.onPageChange((url, title) => {
            const review_queue_router_name = review_queue_router.currentRoute.name;
            if (review_queue_router_name === "review.index") {
              const review_queue_router_name_element = document.querySelectorAll('.reviewable-flagged-post');
              if (review_queue_router_name_element) {
                review_queue_router_name_element.forEach(review_item => {
                  // const buttons = review_item.querySelectorAll('.reviewable-action');
                  const buttons = review_item.querySelectorAll(".reviewable-action-dropdown, .reviewable-action");
                  // console.log("Buttons",buttons);
                  buttons.forEach(button => {
                    const buttonClass = button.classList;
                    // console.log('buton class',buttonClass);post-ignore-and-do-nothing
                    // if (buttonClass.contains('reviewable-action-dropdown', 'post-agree-and-keep-hidden')) { //dev-mode
                    if (buttonClass.contains('reviewable-action-dropdown', 'post-ignore-and-do-nothing')) {
                      // console.log("buttonClass",buttonClass);
                      // console.log("Button", button);
                      const element = button.querySelector('.select-kit-selected-name[data-name="Yes"], .select-kit-selected-name[data-name="Hide Post"]');
                      // console.log("Element", element);
                      if (element) {
                        element.addEventListener('mouseover', function () {
                          // console.log("The element was mouseover,clicked!", button);
                          const reviewableItem1 = button.closest('.reviewable-item');
                          const reviewableId1 = reviewableItem1.dataset.reviewableId;
                          const int_reviewableId1 = Number(reviewableId1);
                          // console.log('data-reviewable-id:',int_reviewableId1);
                          const check_select_kit_body = button.querySelector('.select-kit-body');
                          check_select_kit_body.addEventListener('mouseover', function (event) {
                            // const enqueued = document.querySelectorAll('li[data-value="post-agree_and_keep_hidden"]');
                            // console.log('enqueued',enqueued);
                            const event_target = event.target;
                            // console.log("Event target", event_target);
                            const getEventTarget = event_target.getAttribute('data-value');
                            // if (getEventTarget === 'post-agree_and_keep_hidden') { //dev-mode
                            if (getEventTarget === 'post-ignore_and_do_nothing') {
                              event_target.addEventListener('click', function () {
                                // console.log('got clicked', getEventTarget);
                                getReviewQueue(int_reviewableId1);
                              });
                            }
                          });
                        });
                      }
                    }
                    button.addEventListener('click', function (event) {
                      const reviewableItem = button.closest('.reviewable-item');
                      const reviewableId = reviewableItem.dataset.reviewableId;
                      // console.log('Clicked button for data-reviewable-id:', typeof (reviewableId));
                      const int_reviewableId = Number(reviewableId);
                      // const buttonClass = button.classList;
                      // console.log("ButtonClass->",buttonClass);
                      // if (buttonClass.contains('post-disagree-and-restore')) { //dev-mode
                      if (buttonClass.contains('post-disagree')) {
                        // console.log('Approve Post button clicked', typeof (int_reviewableId), int_reviewableId);
                        getReviewQueue(int_reviewableId);
                      }
                    });
                  });
                });
              }
            }
          });
        });
        //############################################ s - v0.0.1.34 ###################################################

        //############################################ s - v0.0.1.33 ####################################################
        document.addEventListener('DOMContentLoaded', function () {
          const review_router = api.container.lookup("service:router");
          api.onPageChange((url, title) => {
            const review_router_name = review_router.currentRoute.name;
            // console.log('Review Router name', review_router_name);
            const review_site_settings = api.container.lookup("site-settings:main");
            const review_ghostmode_topics = review_site_settings.ghostmode_topics;
            const array_of_review_ghostmode_topics = review_ghostmode_topics.split("|");
            // const container_posts = document.querySelectorAll(".container.posts");
            // console.log('container_posts', container_posts);
            if (review_router_name === "topic.fromParamsNear" || review_router_name === "topic.fromParams") {
              // console.log('if review_router_name === "topic.fromParamsNear"', review_router_name);
              const data_topic_id = document.querySelectorAll('.topic-area');
              // console.log('data_topic_id', data_topic_id);
              if (data_topic_id) {
                data_topic_id.forEach(dti => {
                  const get_data_topic_id_1 = dti.getAttribute('data-topic-id');
                  const isThisTopicHidden = array_of_review_ghostmode_topics.includes(get_data_topic_id_1);
                  if (isThisTopicHidden) {
                    // alert(`Alert: This topic is hidden, and posts cannot be unhidden or hidden.`);
                    const topicTitle = document.getElementById("topic-title");
                    // console.log("Topic Title", topicTitle);
                    if (topicTitle) {
                      topicTitle.style.backgroundColor = "red";
                    }
                    const extraButtonsSpans1 = document.querySelectorAll('.topic-post .extra-buttons');
                    const toggleHidePostButton1 = document.getElementById('topic-footer-button-toggleHidePost');
                    // console.log('toggleHidePostButton', toggleHidePostButton1);
                    if (extraButtonsSpans1) {
                      //&& toggleHidePostButton
                      extraButtonsSpans1.forEach(extraButtonsSpan => {
                        const buttons = extraButtonsSpan.querySelectorAll('button');
                        buttons.forEach(button => {
                          // console.log(button);
                          button.disabled = true;
                          button.classList.add('disabled');
                          //toggleHidePostButton.disabled = true;
                          button.style.cursor = "not-allowed";
                        });
                      });
                    }
                  }
                });
              }
            }
          });
        });
        //############################################ e - v0.0.1.33 ####################################################

        //############################################ s - v0.0.1.30 ####################################################
        document.addEventListener('DOMContentLoaded', function () {
          const reviewable_router = api.container.lookup("service:router");
          api.onPageChange((url, title) => {
            const reviewable_router_name = reviewable_router.currentRoute.name;
            if (reviewable_router_name === "review.index") {
              const reviewable_flagged_element = document.querySelectorAll('.reviewable-flagged-post');
              if (reviewable_flagged_element) {
                reviewable_flagged_element.forEach(post => {
                  const reviewableId = post.getAttribute('data-reviewable-id');
                  const postTopic = post.querySelector('.post-topic');
                  if (postTopic) {
                    const href = postTopic.querySelector('a').getAttribute('href');
                    const data = href.match(/(\d+)/g) || [];
                    const get_topic_id = data[data.length - 2];
                    const get_post_number_one = data[data.length - 1];
                    const int_get_post_number_one = Number(get_post_number_one);
                    if (get_topic_id) {
                      const retrieve_site_settings = api.container.lookup("site-settings:main");
                      const retrieve_ghostmode_topics = retrieve_site_settings.ghostmode_topics;
                      const array_of_retrieved_ghostmode_topics = retrieve_ghostmode_topics.split("|");
                      const isRetrievedTopicHidden = array_of_retrieved_ghostmode_topics.includes(get_topic_id);
                      if (isRetrievedTopicHidden) {
                        if (int_get_post_number_one !== 1) {
                          // const buttonClass = post.querySelector('.btn.btn-text.reviewable-action.post-disagree-and-restore');
                          const buttonClass = post.querySelector('button.btn.btn-text.btn-default.reviewable-action.post-disagree');
                          if (buttonClass) {
                            buttonClass.style.display = "none";
                            postTopic.style.backgroundColor = "red";
                          }
                        }
                      }
                    }
                  }
                });
              }
            }
          });
        });
        //############################################ s - v0.0.1.30 ####################################################

        //############################################ s - v0.0.1.16.1 ####################################################
        document.addEventListener('DOMContentLoaded', function () {
          const router = api.container.lookup("service:router");
          // console.log('router', router);

          api.onPageChange((url, title) => {
            const router_name = router.currentRoute.name;
            // console.log('router_name', router_name);

            if (router_name === "discovery.latest" || router_name === "topic.fromParamsNear" || router_name === "topic.fromParams" || router_name === "discovery.new" || router_name === "discovery.unread" || router_name === "discovery.top" || router_name === "discovery.categories") {
              displayHideTopicDotCSS(api, false);
            }
          });
        });

        //############################################ e - v0.0.1.16.1 ####################################################

        //############################################ s - v0.0.1.16 ####################################################
        displayHideTopicDotCSS(api, true);
        //############################################ e - v0.0.1.16 ####################################################

        //##################################### s - Loading of Posts and Topics ###################################################

        api.decorateWidget("post:after", helper => {
          //console.log("Modal Loading", helper.attrs);
          const postInTopicId = helper.attrs.topicId;
          const str_postInTopicId = postInTopicId.toString();
          const site_Settings = api.container.lookup("site-settings:main");
          const ghostmode_topics = site_Settings.ghostmode_topics;
          const array_of_ghostmode_topics = ghostmode_topics.split("|");
          //const isTopicHidden = ghostmode_topics.includes(str_postInTopicId);
          const isTopicHidden = array_of_ghostmode_topics.includes(str_postInTopicId);
          const toggleTopicElement = document.querySelector("#topic-footer-button-toggleHidePost");
          if (toggleTopicElement) {
            if (isTopicHidden) {
              toggleTopicElement.style.backgroundColor = "red";
            } else {
              toggleTopicElement.style.backgroundColor = "#424949";
            }
          }
        });
        //##################################### e - Loading of Posts and Topics ###################################################

        // ##################### s - Button to Hide Topic #######################
        api.registerTopicFooterButton({
          id: "toggleHidePost",
          icon: "far-eye",
          title: "Show/Hide Topic",
          action() {
            const model = this.attrs;
            //console.log("ModelregisterTopicFooterButton", model);
            const topicId = model.topic.value.id;
            const topicIdstr = topicId.toString();

            //toggleHideTopic(api, topicId);
            toggleHideTopic(api, topicIdstr);
          }
        });
        // ##################### e - Button to Hide Topic #######################

        // ##################### s - Button to Hide Post ################
        api.addPostMenuButton("toggleHidePostButton", model => {
          const siteSettings = api.container.lookup("site-settings:main");
          const ghostmode_posts = siteSettings.ghostmode_posts;
          const array_of_ghostmode_posts = ghostmode_posts.split("|");
          const postId = model.id;
          const postIdstr = postId.toString();
          //const isPostHidden = ghostmode_posts.includes(postId);
          const isPostHidden = array_of_ghostmode_posts.includes(postIdstr);
          return {
            action: "toggleHidePost",
            position: "first",
            className: isPostHidden ? "button.topic_hidden custom-class-hidden" : "button.topic_visible custom-class-visible",
            icon: isPostHidden ? "far-eye-slash" : "far-eye",
            title: isPostHidden ? "Hide Post" : "Show Post"
          };
        });
        // ##################### e - Button to Hide Post ################
        //#################################################################################
        //s - Working of Hiding and showing of posts.
        api.attachWidgetAction("post-menu", "toggleHidePost", function () {
          const model = this.attrs;
          const postId = model.id;
          const postIdstr = postId.toString();
          //const topicId = model.topicId;
          const siteSettings = api.container.lookup("site-settings:main");
          const ghostmode_posts = siteSettings.ghostmode_posts;
          const array_of_ghostmode_posts = ghostmode_posts.split("|");

          //const isPostHidden = ghostmode_posts.includes(postId);
          const isPostHidden = array_of_ghostmode_posts.includes(postIdstr);
          const newGhostModePosts = postId;
          const msg = model.cooked;
          let trimmedMsg = msg.substring(3, msg.length - 4);
          let first25trimmedMsg = trimmedMsg.substring(0, 25);
          if (isPostHidden) {
            //removeSetting(api, postId);
            removeSetting(api, postIdstr);
            alert(`Username : ${model.username}\nPost Id : ${newGhostModePosts} Removed\nPost : ${first25trimmedMsg}`);
            location.reload();
            //api.replaceIcon('far-eye-slash', 'far-eye');
          } else {
            //addSetting(api, postId);
            addSetting(api, postIdstr);
            // eslint-disable-next-line no-alert
            alert(`Username : ${model.username}\nPost Id : ${newGhostModePosts} Added\nPost : ${first25trimmedMsg}`);
            location.reload();
            //api.replaceIcon('far-eye', 'far-eye-slash');
          }
        });
        //e - Working of Hiding and showing of posts.
        //#################################################################################
      });
    }
  }; //#################################################################################
  function toggleHideTopic(api, topicId) {
    const controller = api.container.lookup("site-settings:main");
    const currentGhostmodeTopics = controller.ghostmode_topics || "";
    const currentTopicIds = currentGhostmodeTopics.split("|");

    //const isTopicHidden = currentGhostmodeTopics.includes(topicId);
    const isTopicHidden = currentTopicIds.includes(topicId);
    const toggleTopicElement = document.querySelector("#topic-footer-button-toggleHidePost");
    if (isTopicHidden) {
      // Remove topic ID
      const newGhostmodeTopics = controller.ghostmode_topics.replace(new RegExp(`\\|${topicId}`, "g"), "");
      updateGhostmodeTopics(api, newGhostmodeTopics);
      alert(`Topic ID ${topicId} Removed`);
      location.reload();
      toggleTopicElement.style.backgroundColor = "#424949";
    } else {
      // Add topic ID
      const newGhostmodeTopics = `${currentGhostmodeTopics ? currentGhostmodeTopics + "|" : ""}${topicId}`;
      updateGhostmodeTopics(api, newGhostmodeTopics);
      alert(`Topic ID ${topicId} Added`);
      location.reload();
      toggleTopicElement.style.backgroundColor = "red";
    }
  }
  function updateGhostmodeTopics(api, newGhostmodeTopics) {
    return (0, _ajax.ajax)(`/admin/site_settings/ghostmode_topics`, {
      type: "PUT",
      data: {
        ghostmode_topics: newGhostmodeTopics
      }
    }).then(response => {
      // eslint-disable-next-line no-console
      console.log(response);
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
  }

  // Add the postId to the site settings
  function addSetting(api, postId) {
    const controller = api.container.lookup("site-settings:main");
    const newGhostmodePosts = `${controller.ghostmode_posts ? controller.ghostmode_posts + "|" : ""}${postId}`;
    return (0, _ajax.ajax)(`/admin/site_settings/ghostmode_posts`, {
      type: "PUT",
      data: {
        ghostmode_posts: newGhostmodePosts
      }
    }).then(response => {
      // eslint-disable-next-line no-console
      console.log(response);
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
  }

  // Remove the postId from the site settings
  function removeSetting(api, postIdToRemove) {
    const controller = api.container.lookup("site-settings:main");
    const currentGhostmodePosts = controller.ghostmode_posts || "";
    // eslint-disable-next-line no-unused-vars
    const currentPostIds = currentGhostmodePosts.split("|");
    // Remove the postId to delete
    const newGhostmodePosts = controller.ghostmode_posts.replace(new RegExp(`\\|${postIdToRemove}`, "g"), "");
    return (0, _ajax.ajax)(`/admin/site_settings/ghostmode_posts`, {
      type: "PUT",
      data: {
        ghostmode_posts: newGhostmodePosts
      }
    }).then(response => {
      // eslint-disable-next-line no-console
      console.log(response);
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
  }

  //#################################################################################

  //############################## s - v0.0.1.16 ##########################################################
  function displayHideTopicDotCSS(api, isLoaded) {
    const site_settings_badges = api.container.lookup("site-settings:main");
    const ghostmode_topics_badges = site_settings_badges.ghostmode_topics;
    const hiddenTopicIds = ghostmode_topics_badges.split("|");
    if (isLoaded) {
      document.addEventListener('DOMContentLoaded', function () {
        loadAdminDots();
      });
    } else {
      loadAdminDots();
    }
    function loadAdminDots() {
      document.querySelectorAll('.topic-list-body tr').forEach(row => {
        const topicId = row.getAttribute('data-topic-id');
        // let topicPostBadges = row.querySelector('.topic-post-badges');
        // let linkTopLineSpan = row.querySelector('span.link-top-line');
        let linkTopLineSpan = document.querySelector('head');
        if (linkTopLineSpan) {
          let beforeElement = document.createElement('style');
          let allcss = "";
          hiddenTopicIds.forEach(hiddenIds => {
            allcss += 'tr[data-topic-id="' + hiddenIds + '"] span.link-top-line:before,';
          });
          let css = allcss.substring(0, allcss.length - 1) + ' {content: " ";}';
          beforeElement.appendChild(document.createTextNode(css));
          linkTopLineSpan.appendChild(beforeElement);
        }
      });
    }
    ;
  }
  //############################## e - v0.0.1.16 ##########################################################

  //############################## s - v0.0.1.23 - Unused External APIs ##########################################################
  /*
  function getSinglePost(post_id) {
    return ajax(`/posts/${post_id}.json`, {
      type: "GET",
    });
  }
  
  function getSingleUser(user_id) {
    return ajax(`/admin/users/${user_id}.json`, {
      type: "GET",
    });
  }
  
  function getPostByUsername(user_name) {
    return ajax(`/user_actions.json?offset=0&username=${user_name}`, {
      type: "GET",
    });
  }
  
  function getUserActivity(user_name){
    return ajax(`/${user_name}/activity.json`, {
      type: "GET",
    });
  }
  
  function topicsCreatedByUser(user_name) {
    return ajax(`/topics/created-by/${user_name}.json`, {
      type: "GET",
    });
  }
  
  function getRepliesFromPostId(post_id) {            //https://{defaultHost}/posts/{id}/replies.json
    return ajax(`/posts/${post_id}/replies.json`, {
      type: "GET",
    });
  }
  
  function getSpecificPostsFromTopics(id) {           //https://{defaultHost}/t/{id}/posts.json
    return ajax(`/t/${id}/posts.json`, {
      type: "GET",
    });
  }
  
  function getReviewQueue(review_id) {
    ajax(`/review.json`).then(({ reviewables }) => {
      // console.log('Reviewables', reviewables);
      reviewables.forEach(review => {
        // console.log('Review', review);
        if (review_id === review.id) {
          // console.log("Review ID", typeof (review.id), review.id);
          // console.log("Review Target URL", typeof (review.target_url), review.target_url);
          window.location.href = review.target_url;
        }
      });
    });
  }
  
  
  function reviewList(){
    return ajax (`/review?priority=low&type=ReviewableFlaggedPost&status=reviewed&sort_order=created_at&additional_filters={}`,{
      type : "GET",
    });
  }
  
  */
  //############################## s - v0.0.1.23 - Unused External APIs##########################################################
  //################################## s-redirecting ###############################################################
  /*
        document.addEventListener('mouseover', function (event) {
            const enqueued = document.querySelectorAll('div.post-enqueued-modal');
            enqueued.forEach(function (footerElement) {
                if (footerElement.contains(event.target)) {
                    const button_topic = footerElement.querySelector("button.btn-primary");
                    if (button_topic) {
                        button_topic.addEventListener("click", function () {
                            console.log("Ok Button Clicked");
                            window.location.href = '/my/activity/pending';
                        });
                    }
                }
            });
        });
        */
  //################################## e-redirecting ###############################################################
});